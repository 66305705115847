<template>
  <div class="create">

    <step-container  class="create-title-container" :name="drink.name" :remark="'更新饮品不会现有设备饮品产生影响'" :steps="steps">
    </step-container>

    <div class="create-container">
      <div class="create-container-item">
        <p class="create-container-item-title">{{steps[current].title}}</p>

        <create-basic v-if="current ===0"
                      :select="false"
                      :clear="false"
                      :cups="drink.drinkSpec"
                      :drink="drink" class="create-container-basic"
                      @next="current = current+1"/>
        <create-material v-if="current ===1" :drink="drink" class="create-container-material"
                         @preview="current = current-1"
                         @next="current = current+1"/>
        <create-param v-if="current ===2" :select="false" :drink="drink" class="create-container-param"
                      @preview="current = current-1"
                      @next="handleCommit"/>
      </div>
    </div>
  </div>

</template>

<script>

  export default {
    name: '',
    components: {
      CreateBasic: (resolve) => require(['../components/create_basic'], resolve),
      CreateParam: (resolve) => require(['../components/create_param'], resolve),
      CreateMaterial: (resolve) => require(['../components/create_material'], resolve),
    },
    mounted: function () {
      this.$api('equipment.drinks.detail', {
        uuid: this.$route.params.uuid
      }).then(res => {
        this.drink = res.data
      })
    },
    data: function () {
      return {
        data: [],
        steps: [
          {
            title: '基础配置',
          },
          {
            title: '物料损耗',
          },
          {
            title: '饮品参数',
            pull: true,
          },

        ],
        current: 0,
        drink: {
          name: '',
          engName: '',
          image: '',
          explain: '',
          modelUuid: '',
          drinkSpec: [],
          params: '',
        }
      }
    },
    methods: {
      handleCommit() {
        this.$api('equipment.drinks.update', {},this.drink).then(() => {
          this.$message.success('更新成功')
          this.$router.go(-1)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .create {
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    &-title-container {
      padding: $middle-space $container-padding;
      padding-bottom: $small-space;
      background-color: $color-white;

      ::v-deep .el-steps--simple {
        padding: 0 %8;
        border-radius: 0;
        background: white;

        .el-step__title {
          @include font-medium-s();
        }

        .el-step__arrow:before {
          display: none;
        }

        .el-step__arrow:after {
          width: 80%;
          transform: none;
          height: 1px;
        }
      }

      .tip-container {
        text-align: right;

        .tip {
          width: 184px;
        }
      }


      .remark {
        margin: $small-space 0;
        @include font-little();
        color: $gray-7;
      }

      .container {
        display: flex;

        &-name {
          @include font-large-s();
        }

        &-back {
          padding-left: 4px;
          padding-top: 4px;
        }


      }

    }

    &-container {
      margin: $middle-space;
      margin-bottom: 0;


      &-item {
        padding: $container-padding 0;
        width: 60%;
        min-width: 640px;
        margin: auto;

        &-title {
          @include font-large-s();
          margin-bottom: $middle-space;
          padding-bottom: $small-space;
          border-bottom: 1px solid $content-border-color;
          padding-left: $small-space;
        }
      }

      background-color: $color-white;
      //border-radius: @box-radius;
      &-basic {

      }
    }
  }
</style>
